import {
  MdDone,
  MdTune,
  MdError,
  MdClose,
  MdPerson,
  MdPeople,
  MdWarning,
  MdSettings,
  MdExitToApp,
  MdArrowBack,
  MdAddCircle,
  MdDateRange,
  MdDescription,
  MdErrorOutline,
  MdCloudDownload,
  MdAccountCircle,
  MdKeyboardArrowLeft,
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
} from 'react-icons/md';

export default {
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
  MdKeyboardArrowDown,
  MdAccountCircle,
  MdCloudDownload,
  MdErrorOutline,
  MdDescription,
  MdExitToApp,
  MdDateRange,
  MdArrowBack,
  MdAddCircle,
  MdSettings,
  MdWarning,
  MdPeople,
  MdPerson,
  MdClose,
  MdError,
  MdTune,
  MdDone,
};
