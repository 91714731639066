import React from 'react';
import { node } from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import Card from '../Card/Card';
import Logo from './Logo';

const propTypes = {
  children: node.isRequired,
};

const Main = styled.main(
  css({
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  }),
);

const StyledCard = styled(Card)(
  css({
    minWidth: '300px',
    maxWidth: '445px',
    minHeight: '390px',
    boxShadow: '0px 3px 20px #00000012',
    width: '100%',
  }),
);

const LoggedOutLayout = ({ children }) => (
  <Main>
    <StyledCard withBorderTop>
      <Card.Body p={[8, 10]}>
        <Logo display="block" m="auto" mt="-6.5rem" mb="4" />
        {children}
      </Card.Body>
    </StyledCard>
  </Main>
);

LoggedOutLayout.propTypes = propTypes;

export default LoggedOutLayout;
