import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { node } from 'prop-types';
import { useAuth } from '@bit/medicalwebexperts.mwe-ui.auth-context';

const propTypes = {
  children: node.isRequired,
};

const PrivateRoute = ({ children, ...props }) => {
  const { isLoggedIn } = useAuth();

  const redirectUser = (location) => {
    const { pathname } = location;
    if (pathname === '/') return '/orders';
    return false;
  };

  return (
    <Route
      {...props}
      render={({ location }) =>
        isLoggedIn ? (
          <>
            {redirectUser(location) && (
              <Redirect
                to={{
                  pathname: redirectUser(location),
                }}
              />
            )}
            {children}
          </>
        ) : (
          <Redirect
            to={{
              pathname: '/account/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = propTypes;

export default PrivateRoute;
