import React from 'react';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import { bool, node } from 'prop-types';
import Card from '@bit/medicalwebexperts.mwe-ui.card';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';

const propTypes = {
  buttons: node.isRequired,
  notUnderlined: bool,
};

const defaultProps = {
  notUnderlined: false,
};

const Wrapper = styled(Flex)(
  css({
    py: [5, null, 8],
    maxHeight: 20,
    alignItems: 'center',
    width: '100%',
    'button, a': {
      ':not(:last-of-type)': {
        mr: 2,
      },
    },
  }),
  ({ border }) =>
    border &&
    css({
      borderTop: '1px solid',
      borderTopColor: 'gray.200',
    }),
);

const FooterButtons = ({ notUnderlined, buttons }) => (
  <Card.Header py={0} px={[5, null, 8]}>
    <Wrapper border={!notUnderlined}>
      <Flex.Box flexGrow={1} />
      {buttons}
    </Wrapper>
  </Card.Header>
);

FooterButtons.propTypes = propTypes;
FooterButtons.defaultProps = defaultProps;

export default FooterButtons;
