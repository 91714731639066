import React from 'react';
import { node } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '@bit/medicalwebexperts.mwe-ui.auth-context';

const propTypes = {
  children: node.isRequired,
};

const PublicRoute = ({ children, ...props }) => {
  const { isLoggedIn } = useAuth();

  return (
    <Route
      {...props}
      render={() =>
        !isLoggedIn ? children : <Redirect to={{ pathname: '/' }} />
      }
    />
  );
};

PublicRoute.propTypes = propTypes;

export default PublicRoute;
