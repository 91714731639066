import { bool, string } from 'prop-types';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import BaseCard from '@bit/medicalwebexperts.mwe-ui.card';
import FooterButtons from './FooterButtons';
import Header from './Header';
import Body from './Body';

const propTypes = {
  withBorderTop: bool,
  hideBorder: bool,
  boxShadow: string,
};

const defaultProps = {
  withBorderTop: false,
  hideBorder: false,
  boxShadow: 'none',
};

const Card = styled(BaseCard)(({ withBorderTop, hideBorder }) =>
  css({
    borderRadius: 'lg',
    overflow: 'visible',
    ...(hideBorder
      ? { border: 'none' }
      : {
          border: '1px solid',
          borderColor: 'gray.200',
        }),
    ...(withBorderTop
      ? {
          borderTop: '6px solid',
          borderTopColor: 'primary.dark',
        }
      : null),
  }),
);

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

Card.Footer = BaseCard.Footer;
Card.FooterButtons = FooterButtons;
Card.Header = Header;
Card.Body = Body;

export default Card;
