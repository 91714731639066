import React, { lazy } from 'react';
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ScrollToTop from '@bit/medicalwebexperts.mwe-ui.scroll-to-top';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import PrivateRoute from '../Routes/PrivateRoute';
import PublicRoute from '../Routes/PublicRoute';
import LoggedOutLayout from '../Layout/LoggedOutLayout';
import LoggedInLayout from '../Layout/LoggedInLayout';
import Loader from './Loader';

const NotFoundPage = lazy(() => import('../../pages/404/404'));
const LoginPage = lazy(() => import('../../pages/Account/Login/Login'));
const SettingsPage = lazy(() => import('../../pages/Settings/SettingsPage'));
const OrdersListPage = lazy(() =>
  import('../../pages/Orders/ListPage/ListPage'),
);
const OrdersViewPage = lazy(() =>
  import('../../pages/Orders/ViewPage/ViewPage'),
);

const App = () => (
  <Router>
    <Helmet titleTemplate="%s - Advanced Sleep Medicine Services" />
    <ScrollToTop />
    <ErrorBoundary>
      <Switch>
        <PublicRoute path="/account">
          <LoggedOutLayout>
            <Loader>
              <Switch>
                <Route exact path="/account/login">
                  <LoginPage />
                </Route>
                <Redirect to="/account/login" />
              </Switch>
            </Loader>
          </LoggedOutLayout>
        </PublicRoute>

        <PrivateRoute path="/">
          <LoggedInLayout>
            <Loader>
              <Switch>
                <Route exact path="/orders">
                  <OrdersListPage />
                </Route>
                <Route exact path="/orders/view/:id">
                  <OrdersViewPage />
                </Route>
                <Route exact path="/settings">
                  <SettingsPage />
                </Route>
                <Route path="*">
                  <NotFoundPage />
                </Route>
              </Switch>
            </Loader>
          </LoggedInLayout>
        </PrivateRoute>
      </Switch>
    </ErrorBoundary>
  </Router>
);

export default App;
