import client from './client';
import usersClient from './usersClient';

const authClient = {
  async authenticate(values) {
    const { access, refresh } = await client.post('/auth/user/', values);
    client.$setToken(access, refresh);

    return usersClient.me();
  },
  async logout() {
    client.$clearToken();
  },
  reAuthenticate() {
    if (!client.$getToken() && !client.$getRefreshToken()) {
      throw new Error('Missing auth tokens');
    }

    return usersClient.me();
  },
};

export default authClient;
