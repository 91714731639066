import React from 'react';
import { node } from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { MdMenu } from 'react-icons/md';
import { useUp } from '@bit/medicalwebexperts.mwe-ui.utils.styled';
import BaseSidebar, {
  useSidebarState,
} from '@bit/medicalwebexperts.mwe-ui.sidebar';
import Box from '@bit/medicalwebexperts.mwe-ui.box';
import AppBar from '@bit/medicalwebexperts.mwe-ui.app-bar';
import IconButton from '@bit/medicalwebexperts.mwe-ui.icon-button';
import ProfileDropdown from './ProfileDropdown';
import Sidebar from './Sidebar';
import Logo from './Logo';

const propTypes = {
  children: node.isRequired,
};

const Main = styled.main(({ theme }) =>
  css({
    p: [4, null, null, 8],
    position: 'relative',
    ml: [null, null, null, theme.sizes.sidebar],
    pt: [24, null, null, 0],
  }),
);

const Toolbar = styled.div(
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 16,
    pr: 4,
  }),
);

const LoggedInLayout = ({ children }) => {
  const showAppBar = !useUp('lg');
  const sidebar = useSidebarState({ animated: true });

  return (
    <>
      <Sidebar expand="lg" state={sidebar} ariaLabel="Main Menu" />
      <Main>
        {showAppBar && (
          <AppBar variant="default" boxShadow="0 2px 6px rgba(0, 0, 0, 0.1)">
            <Toolbar>
              <BaseSidebar.Disclosure
                {...sidebar}
                as={IconButton}
                variantColor="primary"
                size="lg"
              >
                <MdMenu />
              </BaseSidebar.Disclosure>
              <Logo width="50px" />
              <Box flexGrow={1} />
              <ProfileDropdown />
            </Toolbar>
          </AppBar>
        )}
        {children}
      </Main>
    </>
  );
};

LoggedInLayout.propTypes = propTypes;

export default LoggedInLayout;
