import React from 'react';
import { NavLink } from 'react-router-dom';
import Dropdown, {
  useDropdownState,
} from '@bit/medicalwebexperts.mwe-ui.dropdown';
import Chip from '@bit/medicalwebexperts.mwe-ui.chip';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';

const avatarClasses = {
  root: {
    color: 'gray.400',
    border: '1px solid',
    borderColor: 'gray.300',
    borderRadius: 'full',
    p: 1,
  },
};

const itemClasses = {
  root: {
    textTransform: 'capitalize',
    justifyContent: 'flex-start',
    color: 'text',
  },
};

const chipClasses = {
  chip: {
    minWidth: 0,
    border: '2px solid',
    boderColor: 'white',
    size: '18px',
  },
};

const ProfileDropdown = () => {
  const dropdown = useDropdownState({ gutter: 5 });

  return (
    <Dropdown state={dropdown}>
      <Dropdown.Toggle
        render={(toggleProps) => (
          <Chip
            {...toggleProps}
            classes={chipClasses}
            content={<Icon name="MdKeyboardArrowDown" size={4} color="white" />}
            position="bottomRight"
            overlap="circle"
            color="success"
          >
            <Icon name="MdPerson" size={10} classes={avatarClasses} />
          </Chip>
        )}
      />
      <Dropdown.Menu bg="white" aria-label="Test">
        <Dropdown.Item
          exact
          to="/"
          as={NavLink}
          classes={itemClasses}
          onClick={dropdown.hide}
        >
          <Icon name="MdPerson" size={19} mr={2} />
          Profile
        </Dropdown.Item>
        <Dropdown.Item classes={itemClasses} onClick={() => {}}>
          <Icon name="MdExitToApp" size={19} mr={2} />
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
