import React from 'react';
import Box from '@bit/medicalwebexperts.mwe-ui.box';

import logo2x from '../../assets/images/logo@2x.png';
import logo from '../../assets/images/logo.png';

const Logo = ({ ...props }) => (
  <Box
    as="img"
    alt="Advanced Sleep Medicine Services"
    srcSet={`${logo} 1x, ${logo2x} 2x`}
    src={logo}
    {...props}
  />
);

export default Logo;
