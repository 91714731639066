export default {
  h1: {
    fontFamily: 'body',
    fontSize: '3xl',
    fontWeight: 'bold',
    lineHeight: 'normal',
  },
  subtitle1: {
    fontFamily: 'body',
    fontSize: 'xl',
    fontWeight: 'semibold',
    lineHeight: 'normal',
  },
  subtitle2: {
    fontFamily: 'body',
    fontSize: 'lg',
    fontWeight: 'medium',
    lineHeight: 'normal',
  },
  body1: {
    fontFamily: 'body',
    fontSize: 'md',
    fontWeight: 'normal',
    lineHeight: 'normal',
  },
  body2: {
    fontFamily: 'body',
    fontSize: 'sm',
    fontWeight: 'normal',
    lineHeight: 'normal',
  },
  caption: {
    fontFamily: 'body',
    fontSize: 'xs',
    fontWeight: 'normal',
    lineHeight: 'normal',
  },
  overline: {
    fontFamily: 'body',
    fontSize: '2xs',
    fontWeight: 'bold',
    lineHeight: 'normal',
  },
  inherit: {},
};
