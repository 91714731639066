import React from 'react';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import { string, node, bool } from 'prop-types';
import Card from '@bit/medicalwebexperts.mwe-ui.card';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';

const propTypes = {
  title: string.isRequired,
  afterTitleElement: node,
  notUnderlined: bool,
  endElement: node,
};

const defaultProps = {
  afterTitleElement: null,
  notUnderlined: false,
  endElement: null,
};

const Wrapper = styled(Flex)(
  css({
    py: [5, null, 8],
    maxHeight: 20,
    alignItems: 'center',
    width: '100%',
  }),
  ({ border }) =>
    border &&
    css({
      borderBottom: '1px solid',
      borderBottomColor: 'gray.200',
    }),
);

const Header = ({ title, endElement, notUnderlined, afterTitleElement }) => (
  <Card.Header py={0} px={[5, null, 8]}>
    <Wrapper border={!notUnderlined}>
      <Typography mr={afterTitleElement ? 2 : null}>
        <strong>{title}</strong>
      </Typography>
      {afterTitleElement}
      <Flex.Box flexGrow={1} />
      {endElement}
    </Wrapper>
  </Card.Header>
);

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
