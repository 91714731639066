import React, { Children } from 'react';
import { shape } from 'prop-types';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import BaseSidebar from '@bit/medicalwebexperts.mwe-ui.sidebar';
import List from '@bit/medicalwebexperts.mwe-ui.list';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import { useAuthDispatch } from '@bit/medicalwebexperts.mwe-ui.auth-context';
import Logo from './Logo';

const propTypes = {
  state: shape({}).isRequired,
};

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
});

const ListItem = styled(List.Item)(({ dark = false }) =>
  css({
    px: 4,
    py: 6,
    color: dark ? 'white' : 'text',
    bg: dark ? 'secondary.dark' : 'transparent',
    transition: 'background-color .5s ease',
    borderRightColor: 'transparent',
    borderRightStyle: 'solid',
    borderRightWidth: '4px',
    fontWeight: 'medium',
    fontFamily: 'body',
    cursor: 'pointer',
    fontSize: 'sm',
    '&:hover': {
      bg: dark ? 'blackAlpha.700' : 'blackAlpha.50',
    },
    '&.ListItem--active': {
      borderRightColor: 'primary.main',
      bg: 'blackAlpha.50',
    },
  }),
);

const sidebarItems = [
  {
    label: 'Orders',
    to: '/orders',
    icon: 'MdDescription',
    external: false,
  },
  {
    label: 'Settings',
    to: '/settings',
    icon: 'MdSettings',
    external: false,
  },
  {
    label: 'Users',
    to: `${process.env.REACT_APP_BPP_BASE_URL}/panel/users`,
    icon: 'MdPeople',
    external: true,
  },
  {
    label: 'Profile',
    to: `${process.env.REACT_APP_BPP_BASE_URL}/panel/profile/me`,
    icon: 'MdAccountCircle',
    external: true,
  },
];

const Sidebar = (props) => {
  const { state } = props;
  const { logout } = useAuthDispatch();

  const handleLogout = async () => {
    await logout();
  };

  const items = Children.toArray(
    sidebarItems.map(({ label, to, icon, external }) =>
      external ? (
        <ListItem
          as="a"
          target="_blank"
          onClick={state.hide}
          href={to}
          rel="noopener noreferrer"
        >
          <List.Item.Icon minWidth={8}>
            <Icon size={22} name={icon} />
          </List.Item.Icon>
          {label}
        </ListItem>
      ) : (
        <ListItem
          exact
          to={to}
          as={NavLink}
          onClick={state.hide}
          activeClassName="ListItem--active"
        >
          <List.Item.Icon minWidth={8}>
            <Icon size={22} name={icon} />
          </List.Item.Icon>
          {label}
        </ListItem>
      ),
    ),
  );

  return (
    <BaseSidebar {...props} classes={{ root: { border: 'none' } }}>
      <Wrapper>
        <Logo width={24} m="auto" mt={8} mb={10} />
        <List as="nav" flexGrow="1">
          {items}
        </List>
        <ListItem dark as="a" onClick={handleLogout}>
          <List.Item.Icon minWidth={8}>
            <Icon size={22} color="white" name="MdExitToApp" />
          </List.Item.Icon>
          Log Out
        </ListItem>
      </Wrapper>
    </BaseSidebar>
  );
};

Sidebar.propTypes = propTypes;

export default Sidebar;
