import React, { Component } from 'react';
import { node } from 'prop-types';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import Link from '@bit/medicalwebexperts.mwe-ui.link';
import Button from '@bit/medicalwebexperts.mwe-ui.button';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import LoggedInLayout from '../Layout/LoggedInLayout';

import image from '../../assets/images/500.svg';

const propTypes = {
  children: node.isRequired,
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <LoggedInLayout>
          <Flex
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            height="calc(100vh - 2rem)"
          >
            <Flex.Box
              as="img"
              alt="500"
              width={['auto', null, '450px']}
              src={image}
              mb={8}
            />
            <Typography paragraph variant="h1" textAlign="center" mt={8}>
              Something went wrong
            </Typography>
            <Typography variant="subtitle2" textAlign="center">
              Please click on the button below to go back to the portal
            </Typography>
            <Button
              mt={8}
              size="lg"
              variantColor="primary.main"
              startIcon={<Icon name="MdArrowBack" size={4} />}
              onClick={() => window.location.reload()}
              as={Link}
            >
              Go back
            </Button>
          </Flex>
        </LoggedInLayout>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = propTypes;

export default ErrorBoundary;
