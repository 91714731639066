import React, { Suspense } from 'react';
import ProgressBar from '@bit/medicalwebexperts.mwe-ui.progress-bar';

const Loader = (props) => (
  <Suspense
    {...props}
    fallback={
      <ProgressBar
        classes={{
          root: { position: 'absolute', top: 0, left: 0 },
        }}
        size="sm"
      />
    }
  />
);

export default Loader;
